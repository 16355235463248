<template>
  <v-container
    id="alerts"
    fluid
    tag="section"
  >
    <div
      id="head-section"
      class="d-flex justify-space-between"
    >
      <header-filters
        class-name="filters"
        :product-options="[
          { id: 'all', value: 'all' },
          { id: 'oem', value: 'OEM' },
          { id: 'fleet', value: 'FLEET' },
        ]"
        selected-product="all"
        :filter-names="['search']"
        search-label="search"
        @onChange="onFiltersChange"
      >
        <!-- <v-card>
            <v-select
              :value="selectedCards"
              dense
              :loading="isLoadingMdModels"
              :items="getCardOptions"
              item-value="id"
              item-text="card_name"
              :label="$t('card_options')"
              multiple
              return-object
              @change="onCardOptions"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.card_name }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ selectedCards.length - 1 }} others)
                </span>
              </template>
            </v-select>
          </v-card> -->
      </header-filters>
      <v-btn
        v-if="$admin.can('alerts.create') && !!selectedRow?.id"
        color="primary"
        dark
        @click="setFormFor({})"
      >
        {{ $t("add_new") }}
      </v-btn>
    </div>
    <!--  -->
    <div>
      <v-card
        :loading="isLoading"
        class="alert-section"
      >
        <v-card class="alert-list">
          <v-card-title> Alerts List </v-card-title>
          <span
            v-for="(r, i) in list"
            :key="i"
            @click="setFormFor(r)"
          >
            <v-card-text :class="r.id == selectedRow?.id ? 'active' : ''">
              <div class="d-flex justify-space-between">
                <span class="alert-name"> {{ r.name }}</span>
                <span class="alert-status">
                  {{ r.is_active ? "Active" : "Inactive" }}
                </span>
              </div>
              <div class="subtitle d-flex justify-space-between">
                <span>
                  {{
                    `${r?.vehicle_names?.length} ${
                      r?.vehicle_names?.length == 1 ? "Vehicle" : "Vehicles"
                    }`
                  }}
                </span>
                <span>{{ r.date }}</span>
              </div>
            </v-card-text>
          </span>
        </v-card>
        <div class="alert-form">
          <AlertForm
            :items="selectedRow"
            :filter-options="options"
            @delete="() => (selectedRow = {})"
            @toggle="() => (selectedRow = {})"
          />
        </div>
      </v-card>
    </div>
    <!--  -->
    <!-- <div class="alert-section">
      <v-card
        elevation="2"
        class="listing"
        :loading="isLoading"
      >
        <v-card-title> Alerts List </v-card-title>
        <span
          v-for="(r, i) in list"
          :key="i"
          @click="setFormFor(r)"
        >
          <v-card-text>
            {{ r.name }}
            <div class="subtitle">
              {{ r?.vehicle_names.join(", ") }}
            </div>
          </v-card-text>
        </span>
      </v-card>
      <div class="action">
        <AlertForm
          :items="selectedRow"
          :filter-options="options"
          @delete="() => (selectedRow = {})"
        />
      </div>
    </div> -->
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Alerts",
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
    AlertForm: () => import("./utils/AlertForm.vue"),
  },
  data() {
    return {
      options: {},
      //
      selectedRow: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "alertsAndNotify/getLoading",
      list: "alertsAndNotify/getList",
    }),
  },
  watch: {
    // "options.tenant_id": {
    //   handler: async function (v) {
    //     if (v) {
    //       const params = {
    //         tenant_id: this.options.tenant_id,
    //       };
    //       await this.$store.dispatch("mdModels/list", params);
    //       await this.getVehicleList();
    //     } else {
    //       this.$store.commit("mdModels/SET_MODEL", []);
    //     }
    //   },
    // },
    // "options.fleetId": {
    //   handler: async function (v) {
    //     if (v) {
    //       const params = {
    //         tenant_id: this.options.fleetId,
    //       };
    //       await this.$store.dispatch("mdModels/list", params);
    //       await this.getVehicleList();
    //     } else {
    //       this.$store.commit("mdModels/SET_MODEL", []);
    //     }
    //   },
    // },
  },
  beforeDestroy() {
    const params = {
      name: "alerts",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  async mounted() {
    this.$store.dispatch("alertsAndNotify/metaData");
  },
  methods: {
    async fetchData() {
      //   const params = {};
      await this.$store.dispatch("alertsAndNotify/list", this.options);
    },
    onFiltersChange(v) {
      //   if (v.tenant_id !== this.options.tenant_id) {
      //     delete this.options.md_model_id;
      //   }
      //   delete this.options.product;
      //   delete this.options.fleetId;
      //   delete this.options.oem_b2b_ids;
      //   delete this.options.tenant_id;
      //   this.options = { ...this.options, ...v };
      this.options = { ...v };
      //   // if (v.tenant_id) {
      //   //   this.onTenantChange();
      //   // }
      if (v.md_make_id && !v.md_model_id) {
        return;
      }
      this.fetchData(v);
    },
    //
    setFormFor(v) {
      this.selectedRow = JSON.parse(JSON.stringify(v));
    },
    // async getVehicleList() {
    //   const params = { ...this.options, vehicles_mqtt_data: true };
    //   await this.$store.dispatch("vehicles/list", params);
    // },
    // async onTenantChange() {
    //   await this.$store.dispatch("mdModels/list", this.options);
    //   // if (this.authUser.isAdmin || this.authUser.user_nature == "fleet") {
    //   // }
    // },
    // onDateChange(v) {
    //   this.options.date = v;
    //   this.fetchData();
    // },
  },
};
</script>
<style lang="sass" scoped>
#filters
  padding: 2rem 2.5rem


.alert-section
    margin-top: 1rem
    display: flex
    justify-content: space-between
    height: 95vh
    // gap: 1rem
    .alert-list
      width: 25%
      margin: 0
      overflow: auto
      .alert-name
        font-weight: 600
      .alert-status
        font-size: 11px
      .v-card__title
        font-size: 16px
        font-weight: 600
      .v-card__text
        font-size: 12px
        cursor: pointer
        padding: 1rem !important
        border-top: 1px solid lightgrey
        &:hover
          background: #eeeeee
      .subtitle
        color: gray
        text-wrap: nowrap
        overflow: hidden
        text-overflow: ellipsis

    .alert-form
      flex: 1

.active , .active .subtitle
  color : #20a390 !important



:deep .v-card__text
        padding: 1rem !important
        border-top: 1px solid lightgrey



@media only screen and (max-width: 960px)
  #head-section
    flex-direction: column
    margin-bottom: 1rem
    .v-btn
      background: yellow
      width: 20%
      align-self: end !important
  .alert-section
    background: transparent
    display: block
    height: auto
    .alert-list
      width: 100%
      margin-bottom: 0.5rem
</style>
